<template>

    <div style="display: inline-flex; margin: 0px 0 7px 40px; padding-top: 15px; width: auto; font-size: 1.0rem;
                 text-align: right;">

        <table border="0" style="" cellpadding="2">
            <tr style="text-align: right; font-weight: bold;">
                <td>
                    symbols:
                </td>
                <td>
                    <b-form-select style="border: 1px solid gray; backgound-color: white; color: black;
                               font-size: 1.1rem; width: auto; margin: 0 0 0 2px;"
                               v-model="symbols"
                               :options="symbolOptions"
                               size="sm"
                               ></b-form-select>
                </td>
            </tr>
            <tr style="text-align: right; font-weight: bold;">
                <td>
                    input type:
                </td>
                <td>
                    <ticker-input-type v-bind:namespace="namespace" style="margin: 15px 0px 0 0px"></ticker-input-type>
                </td>
            </tr>
        </table>
                                        
    </div>
</template>

<script>

    import * as fb from '@/firebase';
    import tickerInputType from '@/components/ticker-input-type';

    export default {
        components: {tickerInputType},
        props: ['namespace', 'index'],
        data: function () {
            return {
                symbolOptions: [
                    {value: "new", text: "Barchart"},
                    {value: "old", text: "traditional"},
                    {value: "ib", text: "Interactive Brokers"}
                ]
            };
        },
        computed: {
            email() {
                // console.log("this.$store.state.user.email=", this.$store.state.user.email);
                return this.$store.state.user.email;
            },
            symbols: {
                get() {
                    return this.$store.state.user.symbols;
                },
                set(symbols) {
                    //this.$store.commit("user/setSymbols", symbols);
                    console.log("this.email=", this.email);
                    fb.usersCollection.doc(this.email)
                            .update({"symbols": symbols})
                            .then(() => {
                                console.log('symbols updated.')
                            });

                }
            }
        }/*,
         methods: {
         tooltip: function () {
         return "unitMove_tooltip";
         }
         }*/
    }
</script>
